import React from 'react';
import moment from 'moment';
import { APP_LABELS } from '@config/app-config';

const getViewDetailsCell = ({ value, row }, viewDetalsPopup) => (
  <button
    type="button"
    onClick={() => viewDetalsPopup(row.original)}
    className="btn btn-link table-link p-0 custom-align"
    data-bs-toggle="modal"
    data-bs-target="#exampleModal"
  >
    {APP_LABELS.DASH_VIEW_DETAILS}
  </button>
);

const getActionCell = viewDetalsPopup => ({
  Header: 'Action',
  accessor: 'viewDetails',
  Cell: ({ value, row }) => getViewDetailsCell({ value, row }, viewDetalsPopup)
});

export const OCHI_BPSM_COLUMNS = viewDetalsPopup => [
  getActionCell(viewDetalsPopup),
  {
    Header: 'ASSN',
    accessor: 'assn',
    className: 'notranslate ymca-word-break'
  },
  {
    Header: 'RECORD ID',
    accessor: 'recordId',
    className: 'notranslate'
  },
  {
    Header: 'SEX',
    accessor: 'sex',
    className: 'notranslate'
  },
  {
    Header: 'AGE',
    accessor: 'age',
    className: 'notranslate'
  },
  {
    Header: 'ETHNICITY',
    accessor: 'ethnicity',
    className: 'notranslate'
  },
  {
    Header: 'EDUCATION',
    accessor: 'education',
    className: 'notranslate'
  },
  {
    Header: 'VISIT DATE',
    accessor: 'visitDate',
    className: 'notranslate'
  },
  {
    Header: 'SYSTOLIC',
    accessor: 'systolic',
    className: 'notranslate'
  },
  {
    Header: 'DIASTOLIC',
    accessor: 'diastolic',
    className: 'notranslate'
  }
];

export const SOLERA_TABLE_COLUMNS = viewDetalsPopup => [
  getActionCell(viewDetalsPopup),
  {
    Header: 'ORGCODE',
    accessor: 'orgcode',
    className: 'notranslate ymca-word-break'
  },
  {
    Header: 'PARTICIP',
    accessor: 'particip',
    className: 'notranslate'
  },
  {
    Header: 'STATE',
    accessor: 'state',
    className: 'notranslate'
  },
  {
    Header: 'GLUCTEST',
    accessor: 'gluctest',
    className: 'notranslate'
  },
  {
    Header: 'GDM',
    accessor: 'gdm',
    className: 'notranslate'
  },
  {
    Header: 'RISKTEST',
    accessor: 'risktest',
    className: 'notranslate'
  },
  {
    Header: ' AGE',
    accessor: 'age',
    className: 'notranslate'
  },
  {
    Header: ' DATE',
    accessor: 'date',
    className: 'notranslate',
    Cell: ({ value }) => moment(value).format('DD MMM YYYY')
  },
  {
    Header: 'WEIGHT',
    accessor: 'weight',
    className: 'notranslate'
  }
];


export const WELLD_TABLE_COLUMNS = viewDetalsPopup => [
  getActionCell(viewDetalsPopup),
  {
    Header: 'ORGCODE',
    accessor: 'orgcode',
    className: 'notranslate ymca-word-break'
  },
  {
    Header: 'PARTICIP',
    accessor: 'particip',
    className: 'notranslate'
  },
  {
    Header: 'STATE',
    accessor: 'state',
    className: 'notranslate'
  },
  {
    Header: 'GLUCTEST',
    accessor: 'gluctest',
    className: 'notranslate'
  },
  {
    Header: 'GDM',
    accessor: 'gdm',
    className: 'notranslate'
  },
  {
    Header: 'RISKTEST',
    accessor: 'risktest',
    className: 'notranslate'
  },
  {
    Header: ' AGE',
    accessor: 'age',
    className: 'notranslate'
  },
  {
    Header: ' DATE',
    accessor: 'date',
    className: 'notranslate'
  },
  {
    Header: ' WEIGHT',
    accessor: 'weight',
    className: 'notranslate'
  }
];

export const DOL_PL_COLUMNS = viewDetalsPopup => [
  getActionCell(viewDetalsPopup),
  {
    Header: 'Particip Id',
    accessor: 'participId',
    className: 'notranslate'
  },
  {
    Header: 'Age',
    accessor: 'age',
    className: 'notranslate'
  },
  {
    Header: 'Gender',
    accessor: 'gender',
    className: 'notranslate'
  },
  {
    Header: 'EnrollDate',
    accessor: 'enrollDate',
    className: 'notranslate',
    Cell: ({ value }) => {
      if (value) return moment(value).format('DD MMM YYYY');
      return '';
    }
  },
  {
    Header: 'Exit Date',
    accessor: 'exitDate',
    className: 'notranslate',
    Cell: ({ value }) => {
      if (value) return moment(value).format('DD MMM YYYY');
      return '';
    }
  }
];

export const DOL_PARTICIPANT_COLUMNS = viewDetalsPopup => [
  getActionCell(viewDetalsPopup),
  {
    Header: 'Association',
    accessor: 'assoc',
    className: 'notranslate'
  },
  {
    Header: 'Zip',
    accessor: 'zip',
    className: 'notranslate'
  },
  {
    Header: 'Pop Served',
    accessor: 'popServed',
    className: 'notranslate'
  },
  {
    Header: 'Other Pop',
    accessor: 'otherPop',
    className: 'notranslate'
  }
];

export const DOL_PARTNERSHIP_COLUMNS = viewDetalsPopup => [
  getActionCell(viewDetalsPopup),
  {
    Header: 'Association',
    accessor: 'assoc',
    className: 'notranslate'
  },
  {
    Header: 'Work Dev',
    accessor: 'workDev',
    className: 'notranslate'
  },
  {
    Header: 'Employer',
    accessor: 'employer',
    className: 'notranslate'
  },
  {
    Header: 'Trade Assoc',
    accessor: 'tradeAssoc',
    className: 'notranslate'
  },
  {
    Header: 'Labor',
    accessor: 'labor',
    className: 'notranslate'
  }
];

export const EF_COLUMNS = viewDetalsPopup => [
  getActionCell(viewDetalsPopup),
  {
    Header: 'ASSN',
    accessor: 'assn',
    className: 'notranslate'
  },
  {
    Header: 'RECORD ID',
    accessor: 'recordId',
    className: 'notranslate'
  },
  {
    Header: 'SEX',
    accessor: 'sex',
    className: 'notranslate'
  },
  {
    Header: 'AGE',
    accessor: 'age',
    className: 'notranslate'
  },
  {
    Header: 'ETHNICITY',
    accessor: 'ethnicity',
    className: 'notranslate'
  },
  {
    Header: 'EDUCATION',
    accessor: 'education',
    className: 'notranslate'
  },
  {
    Header: 'SESSION DATE',
    accessor: 'sessionDate',
    className: 'notranslate'
  },
  {
    Header: 'CHAIRSTAND',
    accessor: 'chairstand',
    className: 'notranslate'
  },
  {
    Header: 'ARMCURL',
    accessor: 'armcurl',
    className: 'notranslate'
  },
  {
    Header: 'UPANDGO',
    accessor: 'upandgo',
    className: 'notranslate'
  }
];

export const LS_COLUMNS = viewDetalsPopup => [
  getActionCell(viewDetalsPopup),
  {
    Header: 'ASSN',
    accessor: 'assn',
    className: 'notranslate'
  },
  {
    Header: 'RECORD ID',
    accessor: 'recordId',
    className: 'notranslate'
  },
  {
    Header: 'SEX',
    accessor: 'sex',
    className: 'notranslate'
  },
  {
    Header: 'AGE',
    accessor: 'age',
    className: 'notranslate'
  },
  {
    Header: 'ETHNICITY',
    accessor: 'ethnicity',
    className: 'notranslate'
  },
  {
    Header: 'EDUCATION',
    accessor: 'education',
    className: 'notranslate'
  },
  {
    Header: 'SESSION DATE',
    accessor: 'sessionDate',
    className: 'notranslate'
  },
  {
    Header: 'SESSION ID',
    accessor: 'sessId',
    className: 'notranslate'
  },
  {
    Header: 'LEGPRESS',
    accessor: 'legpress',
    className: 'notranslate'
  },
  {
    Header: 'CHESTPRESS',
    accessor: 'chestpress',
    className: 'notranslate'
  },
  {
    Header: 'SIXMINWALK',
    accessor: 'sixMinWalk',
    className: 'notranslate'
  }
];

export const HW_COLUMNS = viewDetalsPopup => [
  getActionCell(viewDetalsPopup),
  {
    Header: 'ASSN',
    accessor: 'assn',
    className: 'notranslate'
  },
  {
    Header: 'RECORD ID',
    accessor: 'recordId',
    className: 'notranslate'
  },
  {
    Header: 'SEX',
    accessor: 'childSex',
    className: 'notranslate'
  },
  {
    Header: 'AGE',
    accessor: 'childAge',
    className: 'notranslate'
  },
  {
    Header: 'ETHNICITY',
    accessor: 'childEthnicity',
    className: 'notranslate'
  },
  {
    Header: 'EDUCATION',
    accessor: 'lunchEligibility',
    className: 'notranslate'
  },
  {
    Header: 'SESSION DATE',
    accessor: 'sessionDate',
    className: 'notranslate'
  },
  {
    Header: 'SESSION ID',
    accessor: 'sessId',
    className: 'notranslate'
  },
  {
    Header: 'Height',
    accessor: 'childHeight',
    className: 'notranslate'
  },
  {
    Header: 'Weight',
    accessor: 'childWeight',
    className: 'notranslate'
  }
];