import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash.get';
import PropTypes from 'prop-types';


import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import {
  CDPAdminDashboard,
  CDPAssociatedashboard
} from '@modules/chronic-disease-program/components/index';
import { APP_LABELS, CDP, STORAGE_KEYS } from '@config/app-config';
import SecureStorage from '@utilities/SecureStorage';
import {
  checkHipaaCertificate
} from '@redux/chronic-disease-program/associate-dashboard/actions';

const CdpDashboard = props => {
  const [hippaCertificateStatus, setHipaaCertificateStatus] = useState(true);
  SecureStorage.setItem(STORAGE_KEYS.DASHBOARD_URL, '/cdp/dashboard');
  const { LoginUserData } = props;
  const loggedInUserRole = get(LoginUserData, 'user.role[0]', null);
  const {
    handleCheckHipaaCertificate
  } = props;

  const getCheckHipaaCertificate = async () => {
    const response = await handleCheckHipaaCertificate();
    if (response) {
      setHipaaCertificateStatus(response.data);
    }
  }

  useEffect(async () => {
    await getCheckHipaaCertificate();
  }, []);

  const hippaExpiryMessage = () => {
    return (<div className="container">
      <div className="row my-5 pt-5 justify-content-center">
        <div className="col-10">
          <div className="card ymca-primary-card d-flex align-items-center justify-content-center h-100 mt-2">
            <h2 className="ymca-error-head">
              {
                CDP.DASHBOARD.hippaExpiry
              }
            </h2>
          </div>
        </div>
      </div>
    </div>)
  };

  const renderComponent = () => {
    if (hippaCertificateStatus) {
      switch (loggedInUserRole) {
        case APP_LABELS.YUSA_DATA_STEWARD:
          return <CDPAdminDashboard />;
        case APP_LABELS.CEO_DATA_STEWARD:
        case APP_LABELS.BRANCH_DATA_STEWARD:
        case APP_LABELS.ASSOCIATION_DATA_STEWARD:
          return <CDPAssociatedashboard />;
        default:
          return <div />;
      }
    } else {
      return <>{hippaExpiryMessage()}</>;
    }
  };

  return <>{renderComponent()}</>;
};

CdpDashboard.propTypes = {
  handleCheckHipaaCertificate: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

const mapDispatchToProps = {
  handleCheckHipaaCertificate: checkHipaaCertificate
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CdpDashboard));
