import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CDP } from '@config/app-config';

import CalenderIconGray from '@static/images/calender-icon-gray.svg';
import CalenderIconGreen from '@static/images/calender-icon-Green.svg';
import CalenderIconOrange from '@static/images/calender-icon-orange.svg';
import CalenderIconRed from '@static/images/calender-icon-red.svg';

const DueDate = props => {
  const { DPPDueDateData } = props;
  const [allProgramDueDate, setAllProgramDueDate] = useState([]);

  const setDueDates = kpiDataSummary => {
    const allProgramDates = [];
    // eslint-disable-next-line no-unused-expressions
    kpiDataSummary?.forEach(SummaryDates => {
      allProgramDates.push(SummaryDates);
    });
    setAllProgramDueDate(allProgramDates);
  };

  const getCalenderImage = statusValue => {
    switch (statusValue) {
      case CDP.DUE_DATE_STATUS.UPCOMING:
        return CalenderIconGray;
      case CDP.DUE_DATE_STATUS.COMPLETED:
        return CalenderIconGreen;
      case CDP.DUE_DATE_STATUS.TODAY:
        return CalenderIconOrange;
      case CDP.DUE_DATE_STATUS.OVERDUE:
        return CalenderIconRed;
      default:
        return CalenderIconGray;
    }
  };

  const getCalenderClass = statusValue => {
    switch (statusValue) {
      case CDP.DUE_DATE_STATUS.UPCOMING:
        return '';
      case CDP.DUE_DATE_STATUS.COMPLETED:
        return 'completed-date';
      case CDP.DUE_DATE_STATUS.TODAY:
        return 'warning-date';
      case CDP.DUE_DATE_STATUS.OVERDUE:
        return 'alert-date';
      default:
        return '';
    }
  };

  const showAllDueDates = (dueDate) => (
    <div className="col-12">
      {dueDate.programName === CDP.PROGRAM_NAMES.DPP && (
        <div className='row'>
          <div className="ymca-dark-para-5 font-10">
            Please submit a program to date file for your YMCA’s Diabetes Prevention Program on January 15th and on July 15th every year.

          </div>
        </div>
      )}

      <div className="row">
        <div className="col-lg-3">
          <div className="ymca-dark-para-5 font-10">
            {dueDate.programDescription}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            {dueDate?.submissionDates.map(date => (
              <>
                {dueDate.programName === CDP.PROGRAM_NAMES.DPP &&
                  (<div className="col-lg-5">
                    <div className="card-date">
                      ORG CODE: {date?.orgCode}
                    </div>
                  </div>)}
                <div className="col-lg-7">
                  <div
                    className={`card-date  ${getCalenderClass(
                      date?.status
                    )}`}
                  >
                    <img
                      src={getCalenderImage(date?.status)}
                      alt="CalenderIconGray"
                      className="img-fluid me-2"
                    />
                    {moment(date?.dueDate).format('DD MMM YYYY')}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );


  useEffect(() => {
    window.scrollTo(0, 0);
    setDueDates(DPPDueDateData);
  }, []);

  useEffect(() => {
    setDueDates(DPPDueDateData);
  }, [DPPDueDateData]);

  return (
    <>
      {(allProgramDueDate?.length > 0 &&
        allProgramDueDate.map(date => date?.submissionDates?.length > 0)[0]) && (
          <div className="card ymca-upcoming-card mt-4 mb-3">
            <div className="card-header ymca-dark-para-2">Upcoming Due Dates</div>
            <div className="card-body">
              <div className="row">
                {allProgramDueDate.map(date => date?.submissionDates?.length > 0 && showAllDueDates(date))}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

DueDate.propTypes = {
  DPPDueDateData: PropTypes.func.isRequired
};

export default DueDate;