import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CDP } from '@config/app-config';
import Modal from '@modules/shared/components/reusable-components/model-popup';

const buildRow = (key, row) => {
  if (CDP.VIEW_DETAILS.DATE_FIELDS.includes(key.toLowerCase())) {
    return moment(row[key]).format('DD MMM YYYY');
  }
  return row[key];
};

const redcapErrorSummaryContent = (data, title) => (
  <div className="row mt-3">
    <h5 className="text-danger">{title}</h5>
    <div className="table-responsive mt-1">
      <table className="table table-active table-bordered table-striped table-bg">
        <tbody>
          {Object?.entries(data)?.map(([field, error]) => (
            <tr key={field}>
              <td className="text-black">{field}</td>
              <td className="text-black">{error}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)

const RedcapErrorDetailsR = data => redcapErrorSummaryContent(data.data, "Registration Error Summary");

const RedcapErrorDetailsO = data => redcapErrorSummaryContent(data.data, "OrgCode Error Summary");

const RedcapErrorDetailsV = data => redcapErrorSummaryContent(data.data, "Visits Error Summary");

const RedcapErrorDetailsC = data => redcapErrorSummaryContent(data.data, "Coach Error Summary");

const EfRegistrationErrors = (obj) => redcapErrorSummaryContent(obj.data, obj.title);

const singleSummary = (errorSummary) => (errorSummary &&
  Object.keys(errorSummary)?.filter(c => c !== CDP.ErrorRowIndexField)
    ?.length > 0 && (
    <>
      <h5 className="text-danger">Error Summary</h5>
      <div className="table-responsive mt-1">
        <table className="table table-active table-bordered table-striped table-bg">
          <tbody>
            {errorSummary &&
              Object.keys(errorSummary)
                .filter(c => c !== CDP.ErrorRowIndexField)
                .map(key => (
                  <tr key={key}>
                    <td className="text-black">{key}</td>
                    <td className="text-black">{errorSummary[key]}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </>
  ))


const ViewDetails = props => {
  const { popupTitle, popupData, dataSystem, programName } = props;
  let errorSummary;

  if (dataSystem === CDP.DATA_SYSTEM_NAMES.REDCAP) {
    errorSummary = !popupData.isValidRecord && popupData.errorSummary
      ? JSON.parse(popupData.errorSummary)
      : '';
  } else {
    errorSummary = !popupData.isValidRecord && popupData.errorSummary
      ? JSON.parse(popupData.errorSummary).errors
      : '';
  }

  const items = [];
  Object.keys(popupData).forEach(key => {
    if (CDP.VIEW_DETAILS.EXCLUED_FIELDS.indexOf(key) <= -1) {
      items.push(
        <div key={key} className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading text-uppercase">{key}</div>
          <div className="ymca-modal-content notranslate">
            {popupData[key] ? buildRow(key, popupData) : '-'}
          </div>
        </div>
      );
    }
  });

  const RedcapErrorSummaryR = !popupData.isValidRecord && popupData.erroR_SUMMARY_R
    ? JSON.parse(popupData.erroR_SUMMARY_R).errors
    : '';

  const RedcapErrorSummaryO = !popupData.isValidRecord && popupData.erroR_SUMMARY_O
    ? JSON.parse(popupData.erroR_SUMMARY_O).errors
    : '';

  const RedcapErrorSummaryV = !popupData.isValidRecord && popupData.erroR_SUMMARY_V
    ? JSON.parse(popupData.erroR_SUMMARY_V).errors
    : '';

  const RedcapErrorSummaryC = !popupData.isValidRecord && popupData.erroR_SUMMARY_C
    ? JSON.parse(popupData.erroR_SUMMARY_C).errors
    : '';

  const efRegistrationErrorSummary = !popupData.isValidRecord && popupData.errorSummary && JSON.parse(popupData.errorSummary)?.Registration
    ? JSON.parse((JSON.parse(popupData.errorSummary)?.Registration))
    : '';

  const efVisitErrorSummary = !popupData.isValidRecord && popupData.errorSummary && JSON.parse(popupData.errorSummary)?.Visit
    ? JSON.parse((JSON.parse(popupData.errorSummary)?.Visit))
    : '';

  const efAssesdsementErrorSummary = !popupData.isValidRecord && popupData.errorSummary && JSON.parse(popupData.errorSummary)?.Assessement
    ? JSON.parse((JSON.parse(popupData.errorSummary)?.Assessement))
    : '';



  const summaryContent = () => {
    switch (programName) {
      case CDP.PROGRAM_NAMES.DPP:
        if (dataSystem === CDP.DATA_SYSTEM_NAMES.REDCAP) {
          return (<>
            {RedcapErrorSummaryR && (
              <RedcapErrorDetailsR data={RedcapErrorSummaryR} />
            )}
            {RedcapErrorSummaryO && (
              <RedcapErrorDetailsO data={RedcapErrorSummaryO} />
            )}
            {RedcapErrorSummaryV && (
              <RedcapErrorDetailsV data={RedcapErrorSummaryV} />
            )}
            {RedcapErrorSummaryC && (
              <RedcapErrorDetailsC data={RedcapErrorSummaryC} />
            )}
          </>)
        }
        return singleSummary(errorSummary);
      case CDP.PROGRAM_NAMES.EF:
        if (dataSystem === CDP.DATA_SYSTEM_NAMES.REDCAP) {
          return (<>
            {efRegistrationErrorSummary && (
              <EfRegistrationErrors data={efRegistrationErrorSummary} title="Registrations Error Summary" />
            )}
            {efVisitErrorSummary && (
              <EfRegistrationErrors data={efVisitErrorSummary} title="Visits Error Summary" />
            )}
            {efAssesdsementErrorSummary && (
              <EfRegistrationErrors data={efAssesdsementErrorSummary} title="Fitness Error Summary" />
            )}
          </>)
        }
        return singleSummary(errorSummary);
      default:
        return singleSummary(errorSummary);
    }
  }

  return (
    <Modal
      popupTitle={popupTitle}
      showClose="true"
      classNames="chronic-program"
    >
      {summaryContent()}
      {items}
    </Modal>
  );
};

ViewDetails.propTypes = {
  popupTitle: PropTypes.string.isRequired,
  popupData: PropTypes.objectOf(PropTypes.any).isRequired,
  dataSystem: PropTypes.string,
  programName: PropTypes.string.isRequired
};

ViewDetails.defaultProps = {
  dataSystem: ''
};

export default ViewDetails;
